
import Vue from 'vue';
import HeaderLogoC360 from '../components/toolbar/headerLogoC360.vue';
import { C360Provider, defineAbilityFor } from '@c360/ui';

export default Vue.extend({
  components: {
    HeaderLogoC360,
    AppGlobals: () => import('../global/globals.vue'),
    C360Footer: () => import('../global/footerC360.vue'),
    C360Provider,
  },
  data: () => ({
    refreshToken: '',
    productId: 5,
  }),
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    themeCode(): string {
      return window.location.hostname?.split('.')[0];
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: util.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
        tenantsCount: this.$store.state?.customer?.user?.AvailableAgencies?.length ?? 1,
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
    backgroundColor(): object {
      return { 'background-color': 'var(--c360-background-primary)' };
    },
  },
  created() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).vueApp = this;
  },
  mounted() {
    this.refreshToken = this.$store.state?.customer?.user?.refreshToken;
  },
  watch: {
    '$store.state.customer.user.accessToken': {
      handler(newState: boolean): void {
        this.refreshToken = newState;
      },
    },
  },
  methods: {},
});
